@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
    background-color: #f3f3f3;
    font-family: Roboto, Arial, Verdana;
    color: rgba(0, 0, 0, 0.8);
    height: 100vh;
}

#root {
    height: 100%;
}

.main-container {
    display: block;
    width: 100%;
    height: 100%;
}

.main-content {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.report-container {
    margin: 0 auto;
    flex-grow: 1;
}

.header {
    margin: 0 20px;
    height: 72px;
}

p {
    margin: 0;
}

.ul_loeffa {
    list-style: none;
    margin: 0 -8px;
    padding: 0;
}

.ul_loeffa li {
    background-color: #ffffff;
    margin: 8px;
    border-radius: 8px;
    padding: 16px;
    width: calc(50% - 48px);
    float: left;
    box-shadow: 0 0 8px #0000001c;
}

.ul_loeffa li h4 {
    font-size: 16px;
    margin: 0;
    margin-bottom: 8px;
}

.ul_loeffa li span {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
    line-height: 16px;
}

.ul_loeffa li .progressContainer {
    height: 16px;
    width: 100%;
    border: 1px solid #0000001c;
    text-align: center;
    border-radius: 4px;
}

.ul_loeffa li .progressContainer>span {
    color: #000;
    position: relative;
    top: -10px;
    z-index: 20;
}

.ul_loeffa li .progress {
    height: 16px;
    max-width: 100%;
    border-radius: 4px;
    position: relative;
    width: 0;
}

.ul_loeffa li .workedProgress {
    background-color: #4FAFE2;
    margin-top: -16px;
    z-index: 10;
}

.ul_loeffa li .workingProgress {
    background-color: #4FAFE2;
    opacity: 0.6;
    z-index: 5;
}

.ul_loeffa li .weekdays {
    width: 100%;
    top: -16px;
    z-index: 15;
}

.ul_loeffa li .weekdays span {
    width: calc(20% - 1px);
    float: left;
    height: 16px;
    line-height: 16px;
    color: #e3e3e300;
    z-index: 15;
    border-right: 1px solid #0000001c;
}

.ul_loeffa li .weekdays span:last-child {
    border-right: 0;
}

.button {
    background-color: #4FAFE2;
    color: #ffffff;
    display: inline-block;
    border-radius: 8px;
    border: 0;
    margin-top: 16px;
    padding: 16px 24px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}

.header h1 {
    width: calc(100% - 200px);
    float: left;
    margin-top: 16px;
}

button {
    margin: 8px;
    border-radius: 20px;
    border: 1px solid #4FAFE2;
    color: #4FAFE2;
    background-color: #fff;
    line-height: 12px;
    height: 22px;
    top: -2px;
    cursor: pointer;
    position: relative;
}

button:focus {
    outline: none;
}

.report {
    padding: 0 20px;
}

h3 {
    margin: 12px 0;
}

.userInfo {
    float: right;
    width: 200px;
    color: rgba(0, 0, 0, 0.4);
    text-align: right;
    font-size: 12px;
    margin-top: 16px;
}

.header .userInfo button {
    width: auto;
}

.page {
    display: flex;
}

.footer {
    position: fixed;
    bottom: 0;
    margin-left: 40%;
    color: rgba(0, 0, 0, 0.4);
}

.loeffa-link {
    text-decoration: none;
    color: 'inherit';
    color: rgba(0, 0, 0, 0.7);
    cursor: 'pointer';
}

p.state {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.4);
    margin-top: 0;
    margin-bottom: 4px;
}

.goTo {
    float: right;
}

.update {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.4);
    transition: color 0.3s;
    top: 2px;
    position: relative;
}

.update:hover {
    color: rgba(0, 0, 0, 0.6);
}

/* Style the modal */
.modal {
    position: fixed;
    z-index: 1301;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Modal Content */
.modal-content {
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 5px;
    display: flex;
    align-items: center;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: #fefefe;
}

.modal-text {
    margin-left: 14px;
    font-size: 24px;
}

/* Create the circle loader with pure CSS */
.loader {
    width: 50px;
    height: 50px;
    border: 8px solid #ddd;
    border-top: 8px solid #4FAFE2;
    border-bottom: 8px solid #4FAFE2;
    border-radius: 50%;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-container {
    position: fixed;
    display: flex;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 35%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
}

.loading-spinner {
    border: 8px solid rgba(0, 0, 0, 0.3);
    border-top: 8px solid #4FAFE2;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    -webkit-animation: spin2 1s linear infinite;
            animation: spin2 1s linear infinite;
}

@-webkit-keyframes spin2 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes spin2 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
.Login_main_panel_container__1XO4m {
  background-image: url(/static/media/login_background.fce64de5.png);
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  background-color: #000000;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
}

.Login_input_container__1eOmY {
  position: relative;
  display: block;
}

body {
  margin: 0;
}

form {
  flex-grow: 1;
}

.Login_form_container__1wjJk {
  display: flex;
  flex-direction: column;
}

.Login_form_image__3mghJ {
  max-width: 366px;
  align-self: center;
}

.Login_logon_container__3q5UI {
  width: 428px;
  height: 447px;
  background-color: #ffffff;
  margin: 0;
  border-radius: 20px;
  padding: 32px 20px 32px 20px;
  box-shadow: 0 0 8px #0000001c;
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;
}

.Login_forgot_password_container__2Llii {
  margin-top: 10px;
}

.Login_forgot_password__DhVM6 {
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: center;
  text-decoration: none;
  color: #4FAFE2;
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: none;
}

.Login_login_button__3ulkH {
  font-family: Roboto, Arial, Verdana;
  text-transform: uppercase;
  width: 88px;
  height: 36px;
  padding: 6px 6px 6px 8px;
  border-radius: 4px;
  background-color: #4FAFE2;
  color: #FFFFFF;
  left: 0px;
  margin-left: 0;
  border: 0;
  top: 25px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
}

.Login_input_login__1Y7Xz {
  height: 24px;
  padding: 5px;
  border: 1px solid #ccc;
  outline: none;
  width: 100%;
  margin: 16px 0;
}

.Login_input_login_label__KX0jE {
  position: absolute;
  background-color: #FFFFFF;
  top: 8px;
  left: 8px;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-align: left;
  cursor: default;
}
.login-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 10px;
}

.forgot-password{
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: center;
  text-decoration: none;
  color: #4FAFE2;
}

.input-container {
  position: relative;
}

.login-button {
  font-family: Roboto, Arial, Verdana;
  text-transform: uppercase;
  width: 88px;
  height: 36px;
  padding: 6px 6px 6px 8px;
  border-radius: 4px;
  background-color: #4FAFE2;
  color: #FFFFFF;
  left: 0px;
  margin-left: 0;
  border: 0;
  top: 25px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
}

.input-login {
  height: 24px;
  left: 20px;
  grid-gap: 4px;
  gap: 4px;
  padding: 5px;
  border: 1px solid #ccc;
  outline: none;
}

.input-login-label {
  position: absolute;
  background-color: #FFFFFF;
  top: 8px;
  left: 8px;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-align: left;
}

.logon-container {
    width: 400px;
    background-color: #ffffff;
    margin: 150px auto;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 0 8px #0000001c;
}

h1 {
    margin: 0;
}

p {
    margin-top: 8px;
}

input {
    width: 100%;
    margin: 16px 0;
    border: 0;
    border-bottom: 1px solid #632f8a;
    height: 30px;
}

.logon-button {
    font-family: Roboto, Arial, Verdana;
    text-transform: uppercase;
    height: 35px;
    background-color: #4FAFE2;
    line-height: 6px;
}
.PasswordReset_main_panel_container__2BbbS {
    background-image: url(/static/media/login_background.fce64de5.png);
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
    background-color: #000000;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
}

.PasswordReset_input_container__1OxMZ {
  position: relative;
  display: block;
}

body {
  margin: 0;
}

.PasswordReset_reset_container__1VHzJ {
    width: 428px;
    height: 447px;
    background-color: #ffffff;
    margin: 0;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 0 8px #0000001c;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: flex-start;
}

.PasswordReset_reset_button__1xHbS {
  font-family: Roboto, Arial, Verdana;
  text-transform: uppercase;
  height: 36px;
  padding: 6px 6px 6px 8px;
  border-radius: 4px;
  color: #FFFFFF;
  background-color: #4FAFE2;
  left: 0px;
  margin-left: 0;
  border: 0;
  top: 25px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
}

.PasswordReset_input_login__1AamS {
  height: 24px;
  padding: 5px;
  border: 1px solid #ccc;
  outline: none;
  width: 100%;
  margin: 16px 0;
}

.PasswordReset_input_login_label__2ci8w {
  position: absolute;
  background-color: #FFFFFF;
  top: 8px;
  left: 8px;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-align: left;
  cursor: default;
}

p {
    white-space: pre-wrap;
}

.reset-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 10px;
}

.input-container {
  position: relative;
}

.input-reset {
  height: 24px;
  left: 20px;
  grid-gap: 4px;
  gap: 4px;
  padding: 5px;
  border: 1px solid #ccc;
  outline: none;
}

.input-reset-label {
  position: absolute;
  background-color: #FFFFFF;
  top: 8px;
  left: 8px;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-align: left;
}

.reminders-text {
  height: 120px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #B7B7B7;
}

.error-text {
  color: #ff0000; /* Cor vermelha para indicar erro */
  font-size: 14px; /* Tamanho da fonte menor para a mensagem de erro */
  font-weight: 600; /* Fonte em negrito para enfatizar o erro */
  margin-top: 8px; /* Adicione uma pequena margem superior para separar do conteúdo anterior */
}
.style_main_panel_container__M0tM_ {
    background-color: #f3f3f3;
    font-family: Roboto, Arial, Verdana;
    color: rgba(0, 0, 0, 0.8);
    background-image: none;
}

.style_form_image__3jlL1 {
  width: 72px;
  height: 24px;
  margin: 0 14px;
}

.style_header_texts__37bGq {
  display: flex;
  align-items: center;
  flex-direction: column;
 }
 
.style_header_link__3nBDv {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.8);

}

.style_header_link__3nBDv:visited {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.8);
}


.style_container_links__2ANcQ {
 display: flex;
 align-items: center;
 flex-direction: row;
}

.style_label_links__2LzKF {
  margin: 0px 8px;
  padding: 5px;
}

.style_image_links__OIcda {
  width: 24px;
  margin-left: 5px;
}

.style_side_bar__3sOmn {
  height: 100%;
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.20), 0px 6px 30px 0px rgba(0, 0, 0, 0.12), 0px 16px 24px 0px rgba(0, 0, 0, 0.14);
}

.style_side_bar_collapsed__o0pjw {
  height: 100%;
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.20), 0px 6px 30px 0px rgba(0, 0, 0, 0.12), 0px 16px 24px 0px rgba(0, 0, 0, 0.14);
}

.style_side_bar_collapsed__o0pjw li {
  margin-top: 40px;
}

.style_content_container__BcrtT {
  position: absolute;
  height: calc(100% - 64px);
  max-width: 245px;
}

.style_sidebar_header__2hs11 {
  display:flex;
  text-align: left;
  align-items: flex-start !important;
  justify-content: flex-start;
  margin: 32px 16px 16px 16px;
}

.style_collapsed_links__21eU5 {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px; 
}
button.daysButton {
    width: 22px;
}

ul li .update {
    float: right;
    top: -26px;
}

.date-changer {
    border: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
}

.style_main_panel_container__2zPgQ {
    background-color: #f3f3f3 !important;
    font-family: Roboto, Arial, Verdana !important;
    color: rgba(0, 0, 0, 0.8) !important;
    background-image: none !important;
}

.style_content_main_container__2MMoE {
  display: flex;
  flex-direction: column;
  margin-left: 248px;
}

.style_header_title__3BYyC {
  max-width: 500px;
}

.style_header_buttons__19g1K {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 300px;
}

.style_header_buttons__19g1K p {
  text-align: center;
  margin: 0;
}

.style_header_container__2UiJT {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-right: 120px;
}

.style_content_container__2No2M {
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
}

.style_my_goals_container__3Y4Cl {
  display: flex;
  flex-direction: column;
  min-width: 400px;
  padding: 10px;
  grid-gap: 12px;
  gap: 12px;
}

.style_my_goals_title__7jKkK {
  font-size: 30px;
  width: 200px;
}

.style_goals_card_main_container__12Rin {
  display: flex;
  flex-direction: column;
  max-width: 350px;
  grid-gap: 15px;
  gap: 15px;
}

.style_goals_card_container__2Krta {
  padding: 20px 30px;
  border-radius: 10px;
  background-color: #FFFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.style_goals_card_title__l0Dd- {
  font-size: 18px;
}

.style_goals_card_text__3yMJq {
  margin-top: 12px;
}

.style_arrow_icon__1XY4F {
  width: 24px;
  height: 24px;
  margin: 0;
}

.style_month_infos__g5MN2 {
  display: flex;
}

.style_tooltip__3bbnY {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.style_tooltip-trigger__3Lto8 {
  cursor: pointer;
}
h4 span {
    text-transform: uppercase;
    float: right;
}

.external_links {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.4);
    transition: color 0.3s;
    margin-right: 4px;
}

.external_links:hover {
    color: rgba(0, 0, 0, 0.6);
}

ul li {
    cursor: pointer;
}

ul:after {
    content: "";
    display: table;
    clear: both;
}

.content_main_container {
  display: flex;
  flex-direction: column;
  margin: 0 245px;
}
.imagem_card {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 150px;
    max-height: 100px;
}

.texto_card {
    margin-left: auto;
    margin-right: auto;
    width: 6em
}

.Card {
    height: 160px;
}
.HackThePlanet {
    justify-content: center;
    display: none;
}

.content_main_container {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 255px;
}

.MuiGrid-spacing-xs-3 {
  width: 100% !important;
}
.style_main_panel_container__FyAoH {
    background-image: url(/static/media/login_background.fce64de5.png);
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
    background-color: #000000;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
}

.style_input_container__3WKsR {
  position: relative;
  display: block;
}

body {
  margin: 0;
}

.style_status_container__3VQyR {
    width: 400px;
    height: 447px;
    background-color: #ffffff;
    margin: 0;
    border-radius: 20px;
    padding: 16px;
    box-shadow: 0 0 8px #0000001c;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: flex-start;
}

.status-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 10px;
}

.status-button {
  height: 36px;
  padding: 6px 6px 6px 8px;
  width: 126px;
  border-radius: 4px;
  color: #FFFFFF;
  background-color: #4FAFE2;
  left: 0px;
  margin-left: 0;
  border: 0;
  top: 25px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
}

.status-description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
}

.status-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  grid-gap: 10px;
  gap: 10px;
}
.forgot-password{
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: center;
  text-decoration: none;
  color: #4FAFE2;
}

.input-container {
  position: relative;
  display: flex;
}

.forgot-password-button {
  width: 142px;
  height: 36px;
  padding: 6px 6px 6px 8px;
  border-radius: 4px;
  background-color: #4FAFE2;
  color: #FFFFFF;
  left: 0px;
  margin-left: 0;
  border: 0;
  top: 25px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}

.input-login {
  height: 24px;
  left: 20px;
  grid-gap: 4px;
  gap: 4px;
  padding: 8px 0px 8px 12px;
  border: 1px solid #ccc;
  outline: none;
}

.input-login-label {
  position: absolute;
  background-color: #FFFFFF;
  top: 8px;
  left: 8px;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-align: left;
}

#email {
  max-width: 412px;
}
.ForgotPassword_main_panel_container__1x4yD {
    background-image: url(/static/media/login_background.fce64de5.png);
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
    background-color: #000000;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
}

.ForgotPassword_input_container__1Ruji {
  position: relative;
  display: block;
}

body {
  margin: 0;
}

.ForgotPassword_title__6v8hr {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 10px;
}

.ForgotPassword_forgot_password_container__2hAE1 {
    width: 428px;
    height: 447px;
    background-color: #ffffff;
    margin: 0;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 0 8px #0000001c;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
}

.ForgotPassword_forgot_password__kUC40 {
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-align: center;
    text-decoration: none;
    color: #4FAFE2;
    cursor: pointer;
}

.ForgotPassword_forgot_password_button__3qg-o {
  margin-left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0;
  border: 0;
  width: 143px;
  height: 36px;
}

.ForgotPassword_forgot_password_button__3qg-o button {
  font-family: Roboto, Arial, Verdana;
  text-decoration: none;
  width: 100%;
  height: 100%;
  border: 0;
  margin-left: 0;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
  background-color: #4FAFE2;
  text-transform: uppercase;
  display:inline-block;
  padding: 0px 12px;
  margin: 0;
  border: none;
  background-color: #4FAFE2;
  color: #FFFFFF;
}

.ForgotPassword_return_to_login__xoma6 {
  margin-top: 80px ;
  margin-left: 180px;
}

.ForgotPassword_return_to_login__xoma6 button {
  text-decoration: none;
  font-size: 16px;
  font-weight: 800;
  padding: 0;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #4FAFE2;
  border: none;
  text-decoration: none;
}


.ForgotPassword_error_message__gIIbu {
  color: red;
}
