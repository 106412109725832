.main_panel_container {
    background-image: url('../../static/images/login_background.png');
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
    background-color: #000000;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
}

.input_container {
  position: relative;
  display: block;
}

body {
  margin: 0;
}

.title {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 10px;
}

.forgot_password_container {
    width: 428px;
    height: 447px;
    background-color: #ffffff;
    margin: 0;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 0 8px #0000001c;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
}

.forgot_password {
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-align: center;
    text-decoration: none;
    color: #4FAFE2;
    cursor: pointer;
}

.forgot_password_button {
  margin-left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0;
  border: 0;
  width: 143px;
  height: 36px;
}

.forgot_password_button button {
  font-family: Roboto, Arial, Verdana;
  text-decoration: none;
  width: 100%;
  height: 100%;
  border: 0;
  margin-left: 0;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
  background-color: #4FAFE2;
  text-transform: uppercase;
  display:inline-block;
  padding: 0px 12px;
  margin: 0;
  border: none;
  background-color: #4FAFE2;
  color: #FFFFFF;
}

.return_to_login {
  margin-top: 80px ;
  margin-left: 180px;
}

.return_to_login button {
  text-decoration: none;
  font-size: 16px;
  font-weight: 800;
  padding: 0;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #4FAFE2;
  border: none;
  text-decoration: none;
}


.error_message {
  color: red;
}