.main_panel_container {
    background-image: url('../../static/images/login_background.png');
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
    background-color: #000000;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
}

.input_container {
  position: relative;
  display: block;
}

body {
  margin: 0;
}

.status_container {
    width: 400px;
    height: 447px;
    background-color: #ffffff;
    margin: 0;
    border-radius: 20px;
    padding: 16px;
    box-shadow: 0 0 8px #0000001c;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: flex-start;
}
