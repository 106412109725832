.HackThePlanet {
    justify-content: center;
    display: none;
}

.content_main_container {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 255px;
}

.MuiGrid-spacing-xs-3 {
  width: 100% !important;
}