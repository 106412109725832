.main_panel_container {
  background-image: url('../../static/images/login_background.png');
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  background-color: #000000;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
}

.input_container {
  position: relative;
  display: block;
}

body {
  margin: 0;
}

form {
  flex-grow: 1;
}

.form_container {
  display: flex;
  flex-direction: column;
}

.form_image {
  max-width: 366px;
  align-self: center;
}

.logon_container {
  width: 428px;
  height: 447px;
  background-color: #ffffff;
  margin: 0;
  border-radius: 20px;
  padding: 32px 20px 32px 20px;
  box-shadow: 0 0 8px #0000001c;
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;
}

.forgot_password_container {
  margin-top: 10px;
}

.forgot_password {
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: center;
  text-decoration: none;
  color: #4FAFE2;
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: none;
}

.login_button {
  font-family: Roboto, Arial, Verdana;
  text-transform: uppercase;
  width: 88px;
  height: 36px;
  padding: 6px 6px 6px 8px;
  border-radius: 4px;
  background-color: #4FAFE2;
  color: #FFFFFF;
  left: 0px;
  margin-left: 0;
  border: 0;
  top: 25px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
}

.input_login {
  height: 24px;
  padding: 5px;
  border: 1px solid #ccc;
  outline: none;
  width: 100%;
  margin: 16px 0;
}

.input_login_label {
  position: absolute;
  background-color: #FFFFFF;
  top: 8px;
  left: 8px;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-align: left;
  cursor: default;
}