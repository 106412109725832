.forgot-password{
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: center;
  text-decoration: none;
  color: #4FAFE2;
}

.input-container {
  position: relative;
  display: flex;
}

.forgot-password-button {
  width: 142px;
  height: 36px;
  padding: 6px 6px 6px 8px;
  border-radius: 4px;
  background-color: #4FAFE2;
  color: #FFFFFF;
  left: 0px;
  margin-left: 0;
  border: 0;
  top: 25px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}

.input-login {
  height: 24px;
  left: 20px;
  gap: 4px;
  padding: 8px 0px 8px 12px;
  border: 1px solid #ccc;
  outline: none;
}

.input-login-label {
  position: absolute;
  background-color: #FFFFFF;
  top: 8px;
  left: 8px;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-align: left;
}

#email {
  max-width: 412px;
}