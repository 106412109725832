@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
    background-color: #f3f3f3;
    font-family: Roboto, Arial, Verdana;
    color: rgba(0, 0, 0, 0.8);
    height: 100vh;
}

#root {
    height: 100%;
}

.main-container {
    display: block;
    width: 100%;
    height: 100%;
}

.main-content {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.report-container {
    margin: 0 auto;
    flex-grow: 1;
}

.header {
    margin: 0 20px;
    height: 72px;
}

p {
    margin: 0;
}

.ul_loeffa {
    list-style: none;
    margin: 0 -8px;
    padding: 0;
}

.ul_loeffa li {
    background-color: #ffffff;
    margin: 8px;
    border-radius: 8px;
    padding: 16px;
    width: calc(50% - 48px);
    float: left;
    box-shadow: 0 0 8px #0000001c;
}

.ul_loeffa li h4 {
    font-size: 16px;
    margin: 0;
    margin-bottom: 8px;
}

.ul_loeffa li span {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
    line-height: 16px;
}

.ul_loeffa li .progressContainer {
    height: 16px;
    width: 100%;
    border: 1px solid #0000001c;
    text-align: center;
    border-radius: 4px;
}

.ul_loeffa li .progressContainer>span {
    color: #000;
    position: relative;
    top: -10px;
    z-index: 20;
}

.ul_loeffa li .progress {
    height: 16px;
    max-width: 100%;
    border-radius: 4px;
    position: relative;
    width: 0;
}

.ul_loeffa li .workedProgress {
    background-color: #4FAFE2;
    margin-top: -16px;
    z-index: 10;
}

.ul_loeffa li .workingProgress {
    background-color: #4FAFE2;
    opacity: 0.6;
    z-index: 5;
}

.ul_loeffa li .weekdays {
    width: 100%;
    top: -16px;
    z-index: 15;
}

.ul_loeffa li .weekdays span {
    width: calc(20% - 1px);
    float: left;
    height: 16px;
    line-height: 16px;
    color: #e3e3e300;
    z-index: 15;
    border-right: 1px solid #0000001c;
}

.ul_loeffa li .weekdays span:last-child {
    border-right: 0;
}

.button {
    background-color: #4FAFE2;
    color: #ffffff;
    display: inline-block;
    border-radius: 8px;
    border: 0;
    margin-top: 16px;
    padding: 16px 24px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}

.header h1 {
    width: calc(100% - 200px);
    float: left;
    margin-top: 16px;
}

button {
    margin: 8px;
    border-radius: 20px;
    border: 1px solid #4FAFE2;
    color: #4FAFE2;
    background-color: #fff;
    line-height: 12px;
    height: 22px;
    top: -2px;
    cursor: pointer;
    position: relative;
}

button:focus {
    outline: none;
}

.report {
    padding: 0 20px;
}

h3 {
    margin: 12px 0;
}

.userInfo {
    float: right;
    width: 200px;
    color: rgba(0, 0, 0, 0.4);
    text-align: right;
    font-size: 12px;
    margin-top: 16px;
}

.header .userInfo button {
    width: auto;
}

.page {
    display: flex;
}

.footer {
    position: fixed;
    bottom: 0;
    margin-left: 40%;
    color: rgba(0, 0, 0, 0.4);
}

.loeffa-link {
    text-decoration: none;
    color: 'inherit';
    color: rgba(0, 0, 0, 0.7);
    cursor: 'pointer';
}

p.state {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.4);
    margin-top: 0;
    margin-bottom: 4px;
}

.goTo {
    float: right;
}

.update {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.4);
    transition: color 0.3s;
    top: 2px;
    position: relative;
}

.update:hover {
    color: rgba(0, 0, 0, 0.6);
}

/* Style the modal */
.modal {
    position: fixed;
    z-index: 1301;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Modal Content */
.modal-content {
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 5px;
    display: flex;
    align-items: center;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: #fefefe;
}

.modal-text {
    margin-left: 14px;
    font-size: 24px;
}

/* Create the circle loader with pure CSS */
.loader {
    width: 50px;
    height: 50px;
    border: 8px solid #ddd;
    border-top: 8px solid #4FAFE2;
    border-bottom: 8px solid #4FAFE2;
    border-radius: 50%;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-container {
    position: fixed;
    display: flex;
    width: max-content;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 35%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
}

.loading-spinner {
    border: 8px solid rgba(0, 0, 0, 0.3);
    border-top: 8px solid #4FAFE2;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin2 1s linear infinite;
}

@keyframes spin2 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}